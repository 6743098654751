import React from 'react'
import { Link } from 'react-router-dom'
import { SearchCircleIcon, MailIcon, PhoneIcon } from '@heroicons/react/outline'
import ab from '../assets/ab.ico'

function Footer() {
  return (

    <footer className="text-gray-600 body-font">
        
  <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
    
  <Link to="/" className='flex flex-row items-center' >
  <img src={ab} alt="AboriginalJobLeads"/>
                <p className='text-yellow-500 font-bold font-[Poppins] ml-2 text-2xl'>Aboriginaljobleads</p>
                </Link>
    
    <p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">© 2022 Aboriginaljobleads.ca</p>
   
    <Link to="/terms-and-conditions" className="cursor-pointer text-sm font-bold text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">Terms & Conditions</Link>

    <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
     
      <a href="mailto:enquiries@canadajobleads.ca?subject=AboriginalJobLeads Enquiry"className="ml-3 text-gray-500 cursor-pointer flex items-center font-semibold">
        <MailIcon className="w-6 h-6 mr-2"/> enquiries@canadajobleads.ca
      </a>
    </span>
  </div>
</footer>
  )
}

export default Footer