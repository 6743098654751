import React from 'react';
import ab3 from '../assets/ab3.png';
import ab1 from '../assets/ab1.png';
import ab2 from '../assets/ab2.png';

const Resources = () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="flex flex-wrap w-full mt-20 flex-col items-center text-center">
        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
          Resources
        </h1>
        <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">
          Need help? check out the resources below!
        </p>
      </div>
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
          <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div className="rounded-lg h-64 overflow-hidden">
              <img
                alt="content"
                className="object-cover object-center h-full w-full"
                src={ab2}
              />
            </div>
            <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
              First Nations Public Service Secretariat
            </h2>
            <p className="text-base leading-relaxed mt-2">
              The primary purpose of FNPSS is to create and implement a
              collective plan to support BC First Nation communities and
              organizations across the core public service sectors as they
              pursue excellence in their own governments and administrations.
            </p>
            <a
              rel="noreferrer"
              href="https://fnps.ca/"
              className="text-yellow-500 inline-flex items-center mt-3"
            >
              Learn More
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
          <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div className="rounded-lg h-64 overflow-hidden">
              <img
                alt="content"
                className="object-cover object-center h-full w-full"
                src={ab1}
              />
            </div>
            <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
              Northern Aboriginal Economic Opportunities (NAEOP)
            </h2>
            <p className="text-base leading-relaxed mt-2">
              Through the Northern Aboriginal Economic Opportunities Program
              (NAEOP) CanNor supports greater participation by northern Inuit,
              First Nations and Métis communities and businesses and enables
              them to pursue opportunities for employment, income, and wealth
              creation.
            </p>
            <a
              rel="noreferrer"
              href="https://www.cannor.gc.ca/eng/1385486556734/1385486648146"
              className="cursor-pointer text-yellow-500 inline-flex items-center mt-3"
            >
              Learn More
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
          <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div className="rounded-lg h-64 overflow-hidden">
              <img
                alt="content"
                className="object-cover object-center h-full w-full"
                src={ab3}
              />
            </div>
            <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
              Government of Canada jobs for Indigenous people
            </h2>
            <p className="text-base leading-relaxed mt-2">
              The federal public service is seeking Indigenous people, including
              students and graduates, to build a public service that is more
              inclusive and representative of Canada’s reality.
            </p>
            <a
              rel="noreferrer"
              href="https://www.canada.ca/en/public-service-commission/jobs/services/gc-jobs/jobs-indigenous-people.html"
              className="cursor-pointer text-yellow-500 inline-flex items-center mt-3"
            >
              Learn More
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resources;
